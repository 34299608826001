import { RichText } from "@graphcms/rich-text-react-renderer"
import { Grid, GridRow, GridItem } from "./../Grid"
import { LongTextContainer } from "components/CorpTimelineAccordionWrapper/styles"
import { ReactNode } from "react";

export const CorpGenericContainer = ({children}: {
    children: ReactNode;
}) => (
    <Grid>
        <GridRow columns={16}>
            <GridItem colStart={1} colSpan={16}>
                {children}
            </GridItem>
        </GridRow>
    </Grid>
);