import { IAuthorableContentCard } from './AuthorableContentCard';
import { Dictionary } from './Dictionary';

export interface IFeaturedRecipe {
  __typename: string;
  id: string;
  title: string;
  recipeId: string;
  recipeData: any;
}

export type FeaturedContentItem = IFeaturedRecipe | IAuthorableContentCard;

export interface IFeaturedContent {
  __typename: string;
  id: string;
  title: string;
  superText: string;
  dictionary: Dictionary;
  items: FeaturedContentItem[];
}

export function isAuthorableContentCard(card: FeaturedContentItem): card is IAuthorableContentCard {
  return (card as IAuthorableContentCard).__typename === 'AuthorableContentCard';
}

export function isFeaturedRecipeCard(card: FeaturedContentItem): card is IFeaturedRecipe {
  return (card as IFeaturedRecipe).__typename === 'FeaturedContentReference';
}
