import Rewrite from 'types/Rewrite';

export const getLocalizedPath = (
  rewrite: Rewrite,
  slug?: string,
  //   queryParam?: any,
): string => {
  if (slug) {
    return rewrite.showLocale
      ? `/${rewrite.locale}${rewrite.source.replace(':slug', slug)}`
      : rewrite.source.replace(':slug', slug);
  }
  return rewrite.showLocale ? `/${rewrite.locale}${rewrite.source}` : rewrite.source;
};
