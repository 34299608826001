import { Dictionary } from 'types/Dictionary';
import {
  FeaturedContentItem,
  isAuthorableContentCard,
  isFeaturedRecipeCard,
} from 'types/FeaturedContent';
import { mapDictionaryToRecords } from './mapDictionaryToRecords';

export function mapFeaturedContent(
  cmsItems: FeaturedContentItem[],
  copyDictionary: Dictionary = { dictionaryItems: [] },
  contentHeight: string = '500px',
) {
  const copyDict: Record<string, string> = mapDictionaryToRecords(copyDictionary);

  const difficultMapping = {
    0: copyDict.easy,
    1: copyDict.intermediate,
    2: copyDict.tough,
  };

  const allItems = cmsItems.reduce((items: any[], itm: FeaturedContentItem) => {
    if (isAuthorableContentCard(itm)) {
      return [
        ...items,
        {
          // data: {
          //   title: itm.name,
          //   description: itm.copy,
          //   image: {
          //     desktop: itm.image?.asset?.[0]?.desktop,
          //     mobile: itm.image?.asset?.[0]?.mobile,
          //     hero: itm.image?.asset?.[0]?.hero,
          //     tablet: itm.image?.asset?.[0]?.tablet,
          //   },
          // },
          // showInfo: false,
          // link: itm?.link && { url: itm.link.url, displayName: itm.link.displayName },
          // height: contentHeight,
          // cardVariation: 'content',
          // copyDictionary: copyDict,
          // LinkComponent: 'a',
          title: itm.name,
          subtitle: itm.subtitle,
          copy: itm.copy,
          videoId: itm.videoId,
          image: itm.image?.asset?.[0]?.desktop,
          link: itm?.link && { url: itm.link.url, text: itm.link.displayName },
        },
      ];
    }

    if (isFeaturedRecipeCard(itm)) {
      // prettier-ignore
      // only add the featured recipe card if we have recipe data
      return itm.recipeData?.Id
        ?
        [
          ...items,
          {
            data: {
              id: itm.recipeData.Id,
              title: itm.recipeData.Title,
              description: itm.recipeData.ShortDescription,
              categoryId: itm.recipeData.Category.Id,
              category: itm.recipeData.Category.Title,
              image: {
                desktop:
                itm.recipeData.ActiveChannelReleases[0].ImageExports[0].Resolutions[0].Url,
                mobile:
                itm.recipeData.ActiveChannelReleases[1].ImageExports[0].Resolutions[0].Url,
                hero: itm.recipeData.ActiveChannelReleases[0].ImageExports[0].Resolutions[0].Url,
                tablet:
                itm.recipeData.ActiveChannelReleases[0].ImageExports[0].Resolutions[0].Url,
              },
              information: {
                preparationTime: itm.recipeData.PreparationTimeInMinutesSummaryText,
                rating: itm.recipeData?.rating,
                difficulty: itm.recipeData.Difficulty,
              },
            },
            showInfo: true,
            link: {
              url: `/recipes/${itm.recipeData.slug}`,
              displayName: copyDict?.see_recipe_details || 'See Recipe',
            },
            height: contentHeight,
            cardVariation: 'recipe',
            copyDictionary: difficultMapping,
            LinkComponent: 'a',
          },
        ]
        : items;
    }
    return items;
  }, []);

  return allItems;
}
