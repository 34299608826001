import { Dictionary, DictionaryItem } from 'types/Dictionary';

export const mapDictionaryToRecords = (dictionary: Dictionary) =>
  (dictionary?.dictionaryItems || []).reduce(
    (labels: Record<string, string>, current: DictionaryItem) => ({
      ...labels,
      [current.key]: current.value,
    }),
    {},
  );
