import { Dictionary } from 'types/Dictionary';
import Rewrite from 'types/Rewrite';
import { getLocalizedPath } from './getLocalizedPath';
import { mapDictionaryToRecords } from './mapDictionaryToRecords';

export function mapGenericAuthorableGridCards(
  cmsItems: any[],
  copyDictionary: Dictionary = { dictionaryItems: [] },
  rewrites: Record<string, Rewrite>,
) {
  const copyDict: Record<string, string> = mapDictionaryToRecords(copyDictionary);

  const difficultMapping = {
    0: copyDict.easy,
    1: copyDict.intermediate,
    2: copyDict.tough,
  };

  const allItems = cmsItems.reduce((cards: any[], itm: any) => {
    if (itm.__typename === 'AuthorableContentCard') {
      return [
        ...cards,
        {
          type: 'authorable',
          title: itm.name,
          subtitle: itm.subtitle,
          copy: itm.copy,
          videoId: itm.videoId,
          image: itm.image?.asset?.[0]?.desktop,
          link: itm?.link && { url: itm.link.url, text: itm.link.displayName },
        },
      ];
    }

    if (itm.__typename === 'FeaturedContentReference') {
      if (itm.recipeData?.Id) {
        return [
          ...cards,
          {
            type: 'recipe',
            data: {
              id: itm.recipeData.Id,
              title: itm.recipeData.Title,
              description: itm.recipeData.ShortDescription,
              categoryId: itm.recipeData.Category.Id,
              category: itm.recipeData.Category.Title,
              image: {
                desktop: itm.recipeData.ActiveChannelReleases[0].ImageExports[0].Resolutions[0].Url,
                mobile: itm.recipeData.ActiveChannelReleases[1]?.ImageExports[0].Resolutions[0].Url,
                hero: itm.recipeData.ActiveChannelReleases[0].ImageExports[0].Resolutions[0].Url,
                tablet: itm.recipeData.ActiveChannelReleases[0].ImageExports[0].Resolutions[0].Url,
              },
              information: {
                preparationTime: itm.recipeData.PreparationTimeInMinutesSummaryText,
                rating: itm.recipeData?.rating,
                difficulty: itm.recipeData.Difficulty,
              },
            },
            showInfo: true,
            link: {
              url: getLocalizedPath(rewrites.recipe, itm.recipeData.slug),
              displayName: copyDict?.see_recipe_details || 'See Recipe',
            },
            cardVariation: 'recipe',
            copyDictionary: difficultMapping,
            LinkComponent: 'a',
          },
        ];
      }
      if (itm.tipsAndTricksData?.Id) {
        return [
          ...cards,
          {
            type: 'tips',
            data: {
              id: itm.tipsAndTricksData.Id,
              title: itm.tipsAndTricksData.Title,
              description: itm.tipsAndTricksData.ShortDescription,
              categoryId: itm.tipsAndTricksData.Category.Id,
              category: itm.tipsAndTricksData.Category.Title,
              image: {
                desktop:
                  itm.tipsAndTricksData.ActiveChannelReleases[0].ImageExports[0].Resolutions[0].Url,
                mobile:
                  itm.tipsAndTricksData.ActiveChannelReleases?.[1]?.ImageExports[0].Resolutions[0]
                    .Url,
                hero: itm.tipsAndTricksData.ActiveChannelReleases[0].ImageExports[0].Resolutions[0]
                  .Url,
                tablet:
                  itm.tipsAndTricksData.ActiveChannelReleases[0].ImageExports[0].Resolutions[0].Url,
              },
              information: {
                preparationTime: itm.tipsAndTricksData.PreparationTimeInMinutesSummaryText,
                rating: itm.tipsAndTricksData?.rating,
                difficulty: itm.tipsAndTricksData.Difficulty,
              },
            },
            showInfo: true,
            link: {
              url: getLocalizedPath(rewrites.tipAndTrick, itm.tipsAndTricksData.Id),
              displayName: copyDict?.see_recipe_details || 'See Recipe',
            },
            cardVariation: 'recipe',
            copyDictionary: difficultMapping,
            LinkComponent: 'a',
          },
        ];
      }
      if (itm.articlePage?.slug) {
        return [
          ...cards,
          {
            type: 'article',
            data: {
              id: itm.articlePage?.id,
              title: itm.articlePage?.title,
              description: '',
              categoryId: '',
              category: 'article',
              image: {},
              video: '',
            },
            cardVariation: 'article',
            link: {
              url: `/${itm.articlePage?.slug}`,
              displayName: 'Read more',
            },
            LinkComponent: 'a',
          },
        ];
      }
    }
    return cards;
  }, []);

  return allItems;
}
