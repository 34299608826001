import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';
import ISuggestedData from 'types/ISuggestedData';
import Rewrite from 'types/Rewrite';

const componentsMap = {
  GenericAccordionTopRated: 'GenericAccordionTopRated',
  GenericCardCarouseComponent: 'GenericCardCarouseComponent',
  DynamicCardGrid: 'DynamicCardGrid',
};

const ExtensionComponents = ({
  data,
  containerPosition,
  rewrites,
}: {
  data: any;
  containerPosition: number;
  rewrites: Record<string, Rewrite>;
}): JSX.Element => {
  const [cardData] = useState<ISuggestedData[]>();
  const [queries] = useState<string>('');
  const [accordionData] = useState<any[]>();
  // search from extension

  const componentName = (componentsMap as any)[data?.__typename];
  let componentOutput;
  // rewrites are hardcoded now but will be updated with a new approach
  if (componentName === 'GenericAccordionTopRated') {
    const DynamicGenericAccordionTopRated = dynamic(
      () => (import('./../components/GenericAccordionTopRated').then((mod) => mod.GenericAccordionTopRated) as any),
    ) as any;
    componentOutput = (
      <>
        {accordionData && (
          <LazyLoad once>
            <DynamicGenericAccordionTopRated
              category={data?.super}
              title={data?.title}
              linkText="See recipe"
              data={accordionData}
              $isNumbered
            />
          </LazyLoad>
        )}
      </>
    );
  } else if (componentName === 'GenericCardCarouseComponent') {
    const DynamicGenericCardCarousel = dynamic(() => import('./genericCardCarousel'), {
      ssr: true,
    });
    componentOutput = (
      <>
        {(cardData || data.articles) && (
          <LazyLoad once>
            <DynamicGenericCardCarousel
              carouselData={{
                ...data,
              }}
              data={cardData ? cardData.concat(data?.articles || []) : data?.articles}
              componentPosition={containerPosition}
              // TODO: add locale support and fix this query
              searchLink={`${rewrites.search ? rewrites.search.source : '/search'}?${
                data.pointCtaToArticlesList
                  ? 'category=articles'
                  : `query=${encodeURIComponent(queries)}`
              }`}
            />
          </LazyLoad>
        )}
      </>
    );
  } else if (componentName === 'DynamicCardGrid') {
    const DynamicDynamicCardGrid = dynamic(() => import('./dynamicCardGrids'), {
      ssr: true,
    });
    componentOutput = (
      <>
        {cardData && (
          <LazyLoad once>
            <DynamicDynamicCardGrid
              gridData={{
                ...data,
              }}
              data={cardData}
              componentPosition={containerPosition}
            />
          </LazyLoad>
        )}
      </>
    );
  } else {
    componentOutput = <div />;
  }
  return componentOutput;
};

export default ExtensionComponents;
